@import './layout/layout.less';
@import './auth/auth.less';
* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', Roboto, sans-serif;
}

html,
body {
    height: 100% !important;
}

body {
    background: #fff !important;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit !important;
    color: currentColor !important;
    font-size: 25px !important;
    z-index: 55 !important;
}

.ant-carousel .slick-dots li button {
    background: #5887FF !important;
    opacity: 0.4 !important;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1 !important;
    background: #00ddbf !important;
}

.overflow-hidden {
    overflow: hidden;
}

.carousel__arrow {
    position: absolute;
    cursor: pointer;
    font-size: 25px;
    top: 50%;
}

.arrow--next {
    right: 0;
}

.arrow--prev {
    left: 0;
}

/* the slides */
.slick-slide {
    padding: 0 10px;
}

/* the parent */
.slick-list {
    padding: 0 -10px;
}

.how-work {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    background: #f5f5f5;
    padding: 30px 20px;

    h1 {
        font-size: 50px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.landing-center {
    width: calc(100% - 200px);
    margin: 0 auto;

    @media only screen and (max-width:768px) {
        width: 100%;
    }
}

.how-work-wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        height: auto;
        width: 100%;
        max-width: 150px;
        height: 125px;
    }

    div {
        margin-top: 10px;
        font-size: 18px;
        text-transform: uppercase;
        color: #666;
        font-style: italic;
        max-width: 200px;
        width: 100%;
    }
}
.rbc-toolbar-label {
    font-weight: bold;
    color:#5887FF;
}
.rbc-header {
    color: #5887FF;
}
.rbc-time-slot {
    .rbc-label {
        font-weight: bold;
    }
}
.rbc-toolbar {
    .rbc-toolbar-label {
        flex-grow: 1;
        text-align: right;
    }
}
.rbc-btn-group {
    button {
        outline: none;
        border: 1px solid #5887FF;
        color: #5887FF;
        cursor: pointer;
        &:hover {
            background-color: #5887FF;
            color: #fff;
            transition: 500ms all;
        }
        &:focus {
            color: #fff;
            background-color: #5887FF;
            border-color: #5887FF;
        }
        &:focus-visible {
            border: 1px solid #5887FF;
            color: #5887FF;
            background: #fff;
        }
    }
}
.how-work-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: center;

    img {
        width: 100px;
        transform: rotate(90deg)
    }

    @media only screen and (max-width:768px) {
        img {
            display: none;
        }
    }
}

.inner-document-card {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: calc(100% - 150px);
    background: #f5f5f5;
    padding: 15px;

    .inner-document-title {
        font-size: 20px;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #666;
        font-weight: bold;
    }

    .inner-document-content {
        font-size: 15px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: #666;
    }

    .inner-document-action {
        display: flex;
        margin-top: 20px;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        div {
            flex: 1;
            flex-flow: row nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #666;
        }
    }
}

.align-end {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.avatar-uploader>.ant-upload {
    height: 150px !important;
    width: 150px !important;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: calc(25% - 2px);
    margin-right: 2px;
    height: 100px;
  }
  
  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
.avatar {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.profile-route {
    color: #5887FF;
    font-size: 18px;
    padding: 2.5px 10px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.profile-route-active {
    width: 3px;
    margin-right: 15px;
    height: 30px;
}

.profile-route-bolder {
    font-weight: 700;
}

.profile-card {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    background-color: #fff;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    margin-bottom: 10px;
    padding: 30px;
}

.profile-contain {
    display: flex;
    flex-flow: column nowrap;
    align-self: flex-start;
    padding-left: 20px;

    .profile-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #5887FF;
        font-size: 35px;
        font-weight: bold;
    }

    .profile-id {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        font-weight: 300;
        color: #666;
        font-size: 20px;
    }
}

.profile-avatar {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer
}
.rbc-time-slot { min-height:45px !important; }
.avataredit {
    position: absolute !important;
    top: 100px;
    left: 100px;
}

.message {
    display: flex;
    flex-flow: column nowrap;
    .message-wrapper {
      display: flex;
      align-items: center;
      .message-main {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        .message-title {
          font-size: 16px;
          font-weight: bold;
          color: #222;
        }
        .message-content {
          font-weight: 14px;
          font-weight: normal;
          color: #333;
        }
      }
    }
    .message-info {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      .message-user {
        margin: 0px 5px;
      }
    }
  }

  .hide-on-phone {
    @media only screen and (max-width:768px) {
        display: none !important;
    }
  }

  .show-on-phone {
    display: none;
    @media only screen and (max-width:768px) {
        display: block !important;
    }
  }

  .overflow-hidden-m {
    @media only screen and (max-width:768px) {
     overflow:hidden !important;
    }
  }
@primary-color: #00ddbf;@purple-color: #5887FF;