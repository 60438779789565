.user-form {
    max-width: 500px;
    width: 100%;
    padding: 5px;
}

.user-form-forgot {
    float: right;
}

.ant-col-rtl .user-form-forgot {
    float: left;
}

.user-form-button {
    width: 100%;
}