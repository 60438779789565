.overflow-hidden {
    overflow: hidden;
}

.full-height {
    min-height: 100vh;
    background: #f5f5f5;
}

.full-height--center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pd-15 {
    padding: 15px;
}

.active-navlink {
    font-weight: bold;
    color: #0c2340;
}

#header {
    z-index: 55;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    height: 150px;
    background: #fff;
    align-items: center;
    padding: 0px 24px;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    justify-content: space-between;

    .menu-items {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 0 1 auto;
        overflow: hidden;
        width: 75%;
        padding: 0 0 0 3%;

        img {
            margin-right: 10px;
        }

        button {
            a {
                text-transform: uppercase;
                color: #5887FF;
                font-size: 16px;
            }
        }
    }

    .menu-action-controller {
        flex: 0 0 200px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        .menu-switch {
            font-size: 16px;
            color: #5887FF;
            margin-right: 10px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            div {
                margin-right: 5px;
            }

            .ant-switch-checked {
                background: #5887FF;
            }
        }
    }

}

.menu-auth {
    button {
        border: 1px solid #5887FF;
        margin: 0px 5px;

        &:nth-child(odd) {
            background: #5887FF;

            a {
                color: #fff;
            }
        }

        &:nth-child(even) {
            background: #fff;

            a {
                color: #5887FF;
            }
        }
    }
}

#footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 20px 24px;
    flex: 1;
    box-shadow: 0px -5px 5px rgba(208, 216, 243, 0.6);
    background: #5887FF;

    .stores {
        display: flex;
        flex-flow: row nowrap;
        flex: 1;

        div {
            width: 150px;
            margin: 0px 5px;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .copyright {
        margin-top: 10px;
        color: #fff;
    }

}

.footerul {
    list-style: none;
    cursor: pointer;
    color: white;
    padding-left: 0px !important;
}

.footerli {
    color: white;
}

.footerh3 {
    color: white;
    font-size: 18px;
}

.card {
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
}

.card-header {
    padding: 15px;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-body {
    padding: 0;
    margin: 0;
    flex: 1 1 auto;
    min-height: 1px;
}

// Table css
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: black;

    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
        text-align: center;
        border-top: 1px solid black;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid black;
    }

    tbody+tbody {
        border-top: 2px solid black;
    }
}

/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 20px;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    min-width: 75%;
    min-height: 80%;
    margin: 5% 5% 15% 15%;
    border: 1px solid #000;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
}

.modal-header {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    ;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
    height: 82%;
}

.modal-footer {
    display: flex;
    height: 8%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.close-button {
    float: right !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .5 !important;
    border: 0 !important;
    background-color: transparent !important;
}

.close-button-footer {
    float: right !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .5 !important;
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.modal-body-session {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
    height: 82%;
}